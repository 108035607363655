// PROJECT SCSS VARIABLES

// not all of these are available as css custom properties by default: they can be added @ styles/global.scss

// GRID: defaults to 12 columsn with 30px gap as $gutter;

// grid-gap
$gutter: min(3vw, 30px);
$gutter-neg: max(-3vw, -30px);
$gutter-wide: min(4vw, 40px);

// grid columns
$grid-column-count: 12;

// point at which container reaches width limit (unless fluid=true), and responsive font-sizes max-out
$page-width: 1360px;
$page-gutter-desktop: 40px;
$sidebar-width-desktop: 208px;

// mq for page full-width
$page-limit: $page-width + ($page-gutter-desktop * 2);

// use rem here for consistent spacing rules?
$text-spacing: 1rem;

// COMMON BREAKPOINTS: used for consistent measuring breakpoints
// also used in <Grid /> component breakpoints
$min-mobile: 480px;
$min-tablet: 720px;
$min-desktop: 1080px;
$min-wide: 1400px;

$max-mobile: $min-mobile - 1px;
$max-tablet: $min-tablet - 1px;
$max-desktop: $min-desktop - 1px;
$max-wide: $min-wide - 1px;

// map for breakpoint values (used in GridItem classnames)
$ui-breakpoints: (
  'mobile': $min-mobile,
  'tablet': $min-tablet,
  'desktop': $min-desktop,
);

// MISC ELEMENT DIMENSIONS
$header-height: 112px;
$header-height-mobile: 60px;

// Global STACK of elements
$zindex-header: 1;

// COLORS - these are also generated as :root css variables in global.scss

// theme
$color-primary-100: var(--color-primary-100, #f1faff);
$color-primary-200: var(--color-primary-200, #d9f0fd);
$color-primary-400: var(--color-primary-400, #98c1d9);
$color-primary-600: var(--color-primary-600, #3d5a80);
$color-primary: var(--color-primary, $color-primary-600);
$color-primary-text: var(--color-primary-text, #000);
$color-primary-ui: var(--color-primary-ui, $color-primary-600);

$color-primary-rgb: var(--color-primary-rgb, 61, 90, 128);
$color-primary-100-rgb: var(--color-primary-100-rgb, 241, 250, 255);
$color-loading-border: var(--color-loading-border, $color-primary-rgb);

$color-secondary-100: #d8d8d8;
$color-secondary-200: #b0b1b3;
$color-secondary-300: #898a8c;
$color-secondary-400: #626366;
$color-secondary-600: #252525;
$color-secondary-75: #f0f0f2;
$color-secondary-50: #f9f9fb;
$color-secondary-25: #fafafa;
$color-secondary-white: #ffffff;

$color-gray-600: #4f4f4f;

$color-secondary: $color-secondary-400;

// misc
$color-background: #fafafa;
$color-border: $color-secondary-100;
$color-font: $color-secondary-600;
$color-link: #1b61cb;
$color-placeholder: $color-secondary-400;

$color-error: #ee6c4d;
$color-red: #f44336;
$color-green: #0e844b;
$color-green-light: #99d98c;
$color-grey-100: #efefef;

$color-status-processing: $color-link;
$color-status-error: $color-red;
$color-status-accepted: $color-green-light;
$color-status-canceled: $color-secondary;

$color-status-pill-red: #dd2b00;
$color-status-pill-green: #0c7442;
$color-status-pill-purple: #5a0877;
$color-status-pill-blue: #1b61cb;
$color-status-pill-rose: #990a59;

// TYPE (default is a locally-hosted set up)
$font-family: brother-1816, sans-serif;
$font-family-monospace: monospace, sans-serif;

// font-sizes
$font-size: 16px;
$font-size-min: 15px;
$font-size-xs: 14px;
$font-size-xs-min: 13px;
$line-height: math.div(27, 16);

$font-size-button: 16px;
$font-size-button-min: 14px;

// used in heading mixins (styles/type)
$title-xlarge-size: 40px;
$title-xlarge-size-min: 30px;
$title-large-size: 32px;
$title-large-size-min: 26px;
$title-medium-size: 24px;
$title-medium-size-min: 20px;
$title-small-size: 16px;
$title-small-size-min: 14px;

$heading-spjold-size: 18px;
$heading-spjold-size-min: 16px;

$numbers-size: 28px;
$numbers-size-min: 20px;

$paragraph-large-size: 20px;
$paragraph-large-size-min: 18px;
$paragraph-small-size: $font-size-xs;
$paragraph-small-size-min: $font-size-xs-min;

// form elements
$form-width: 296px;
$button-height: $font-size * 3;
$button-width: $form-width;
$input-height: $font-size * 3;

// ANIMATION [power1, 2, 3, 4] in gsap;

// ease-in
$ease-in-quad: cubic-bezier(0.11, 0, 0.5, 0);
$ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
$ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);
$ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);

// ease-out
$ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
$ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$ease-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-out-quint: cubic-bezier(0.83, 0, 0.17, 1);

// ease-in-out
$ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);

$default-ease: $ease-in-out-cubic;

$transition-duration: 300ms;

// element lists (for genetic style loops)
$input-types: date, datetime-local, email, month, number, password, search, tel, text,
  time, url, week;
$heading-types: h1, h2, h3;
$block-article-elements: p, ul, ol, dl, blockquote, pre;

// Extra variables TODO: fix these
$ui-spacing: 8px;
$ui-spacing-md: $ui-spacing * 2;
$ui-spacing-lg: $ui-spacing * 4;
$card-radius: 4px;
$color-brand: $color-border;
$color-border-darken: darken($color-border, 5%);
$content-gutter-min: 16px;
