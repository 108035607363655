@import '@hxm/styles/config';

.formLayout {
  &__item {
    margin-bottom: $gutter;

    &:last-child {
      margin-bottom: 0;
    }

    > button {
      margin-top: 0;
    }
  }
  &__fields {
    display: flex;
    flex-direction: column;

    gap: var(--gutter);

    margin-block-end: var(--gutter);

    > * {
      width: 100%;
    }
  }
}
