/* shared styles for misc components uner components/form-elements/* */
$input-padding-mobile: px-to-rem($ui-spacing);
$input-padding: px-to-rem($ui-spacing * 2);
$input-padding-small: px-to-rem(12px);
$input-padding-medium: px-to-rem(12px);
$input-padding-large: px-to-rem($ui-spacing * 2);

@mixin focus-state {
  border-color: $color-secondary-100;
  outline: 1px solid $color-secondary-100;
}

@mixin form-states {
  width: 100%;

  border: 1px solid $color-border;
  border-radius: 4px;
  outline: none;

  ~ label {
    @include paragraph-small;
    order: -1;

    margin-bottom: 8px;

    width: 100%;

    color: var(--color-input-label, $color-font);
  }

  &:focus {
    @include focus-state;
  }

  &:disabled {
    opacity: 1;

    color: var(--input-disabled-color, $color-secondary-400);
    background-color: var(--input-disabled-background, $color-secondary-100);
  }

  &:focus ~ label {
    color: $color-secondary-600;
  }

  .error & {
    border-color: $color-red;
    outline: 1px solid $color-red;

    caret-color: $color-red;

    ~ label {
      color: $color-red;
    }
  }
}

@mixin select-defaults {
  appearance: none;
  display: block;

  padding-top: 0;
  padding-right: $input-padding-mobile;
  padding-bottom: 0;

  padding-left: $input-padding-mobile;

  width: 100%;
  height: $input-height;

  border: 1px solid $color-secondary-100;
  border-radius: 0;

  background-color: #fff;
  caret-color: $color-secondary-600;

  @media (min-width: $min-tablet) {
    padding-left: $input-padding;
    padding-right: $input-padding;
  }

  &::-webkit-input-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }

  &::-moz-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }

  &:-ms-input-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }
}

@mixin input-appearance($select: false) {
  appearance: none;
  display: block;

  width: 100%;

  border: 1px solid $color-secondary-100;

  background-color: #fff;
  caret-color: $color-secondary-600;

  &::-webkit-input-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }

  &::-moz-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }

  &:-ms-input-placeholder {
    color: var(--color-placeholder, $color-placeholder);
  }

  @if $select != true {
    &:read-only {
      pointer-events: none;

      background-color: var(--color-input-background-readonly, #{$color-secondary-50});
    }
  }
}

@mixin input-default {
  --input-height: #{$input-height};

  padding-top: 0;
  padding-right: $input-padding-mobile;
  padding-bottom: 0;
  padding-left: $input-padding-mobile;

  height: var(--input-height);

  font-size: $font-size;

  @media (min-width: $min-tablet) {
    padding-left: $input-padding;
    padding-right: $input-padding;
  }
}

@mixin input-small {
  --input-height: #{px-to-rem(32)};

  padding-top: 0;
  padding-bottom: 0;
  padding-right: $input-padding-mobile;
  padding-left: $input-padding-mobile;

  height: var(--input-height);

  font-size: $font-size-xs;

  @media (min-width: $min-tablet) {
    padding-left: $input-padding-small;
    padding-right: $input-padding-small;
  }
}

@mixin input-medium {
  --input-height: #{px-to-rem(40)};

  padding-top: 0;
  padding-bottom: 0;
  padding-right: $input-padding-mobile;
  padding-left: $input-padding-mobile;

  height: var(--input-height);

  font-size: $font-size;

  @media (min-width: $min-tablet) {
    padding-left: $input-padding-large;
    padding-right: $input-padding-large;
  }
}

@mixin input-large {
  --input-height: #{px-to-rem(56)};

  padding-top: 0;
  padding-bottom: 0;
  padding-right: $input-padding-mobile;
  padding-left: $input-padding-mobile;

  height: var(--input-height);

  font-size: $font-size;

  @media (min-width: $min-tablet) {
    padding-left: $input-padding-large;
    padding-right: $input-padding-large;
  }
}

// used on checkbox / radio inputs to create dummy styled versions
@mixin checkbox-radio-input($size: 24px) {
  appearance: none;
  display: block;

  width: 1px;

  border: none;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    margin-top: auto;
    margin-bottom: auto;

    width: $size;
    height: $size;

    border: 1px solid $color-border;
    border-radius: 2px;

    pointer-events: none;

    background-color: #fff;

    transition: background-color 150ms $default-ease, border-color 150ms $default-ease;
  }

  &:focus::before {
    border-color: $color-primary;
  }

  &:checked::before {
    border-color: $color-primary;

    background-color: $color-primary;
  }

  &:disabled::before {
    border-color: $color-border;

    background-color: $color-secondary-100;
  }

  &:focus:checked:not(:disabled)::before {
    border-color: $color-primary;
  }
}

// default styles for non-float labels
@mixin form-label($size) {
  user-select: none;

  // line-height: $line-height;

  @media (max-width: $max-desktop) {
    padding-left: calc(#{$size} + 10px);
  }

  @media (min-width: $min-desktop) {
    padding-left: calc(#{$size} + 20px);
  }

  .disabled & {
    color: $color-secondary-400;
  }
}

@mixin login-defaults {
  display: inline-block;

  padding-left: scale-to-em(24px);

  width: 100%;
  height: scale-to-em(64px);

  border-radius: 5px;

  border: 1px solid var(--color-border);
}

// misc border styles based on current form states
@mixin login-focus($valid-styles: true) {
  &:focus {
    --color-border: $color-secondary-400;
  }

  .error &,
  .error &:focus,
  &:invalid:not(:placeholder-shown) {
    --color-border: var(--color-error, #c00);

    ~ label {
      color: var(--color-error, #c00);
    }
  }

  @if ($valid-styles == true) {
    &:required:valid:not(:placeholder-shown) {
      --color-border: $color-secondary-400;

      ~ label {
        color: $color-secondary-400;
      }
    }
  }
}

@mixin login-label($offset: 24px) {
  position: absolute;

  top: 10px;
  left: $offset;

  padding-right: 10px;

  line-height: 1.1;
  font-size: $font-size-xs;

  color: $color-secondary-400;

  transition: opacity $transition-duration $default-ease;
}
