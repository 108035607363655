// all font/type-based mixins
// RESPONSIVE FONT

// mixin scale font-size according to viewport
// basic usage: @include responsive-font($min-value, $max-value);
// arguments are stripped of units (so you can pass in px or non-px values) but re-rendered as pixel-based values
@mixin responsive-font(
  $min-size: $font-size-min,
  $max-size: $font-size,
  $viewport-min: $min-mobile,
  $viewport-max: $page-width
) {
  $min: strip-unit($min-size);
  $max: strip-unit($max-size);
  $v-min: strip-unit($viewport-min);
  $v-max: strip-unit($viewport-max);

  // compute fluid font size between min / max values based on viewport size
  $f: div(($max - $min), ($v-max - $v-min));
  $slope: $f * 100vw;
  $intercept: ($min * 1px) - ($f * $viewport-min);
  $fluid-size: calc(#{$slope} + #{$intercept});

  // default
  font-size: $min * 1px;

  // should always be the case
  @if $min < $max {
    // clamp font-size
    @supports (font-size: clamp(1px, 2px, 3px)) {
      font-size: clamp(#{$min * 1px}, #{$fluid-size}, #{$max * 1px});
    }

    // if clamp not supported, use max font size at desktop
    @supports not (font-size: clamp(1px, 2px, 3px)) {
      @media (min-width: $min-desktop) {
        font-size: $max * 1px;
      }
    }
  }
}

@mixin heading($size-min: $title-large-size-min, $size: $title-large-size) {
  @include responsive-font($size-min, $size);
  font-weight: 400;
}

@mixin title-xlarge {
  @include heading($title-xlarge-size-min, $title-xlarge-size);
  margin-bottom: $text-spacing;

  font-weight: 500;
  line-height: div(56, 40);

  color: var(--color-primary-ui, $color-primary);
}

@mixin title-large {
  @include heading($title-large-size-min, $title-large-size);
  margin-bottom: $text-spacing;

  font-weight: 500;
  line-height: div(40, 32);

  color: var(--color-primary-ui, $color-primary);
}

@mixin title-medium {
  @include heading($title-medium-size-min, $title-medium-size);
  margin-bottom: $text-spacing;

  font-weight: 500;
  line-height: div(32, 24);

  color: var(--color-primary-ui, $color-primary);
}

@mixin title-small {
  @include heading($title-small-size-min, $title-small-size);
  margin-bottom: $text-spacing;

  font-weight: 700;
  line-height: div(24, 16);

  color: var(--color-primary-ui, $color-primary);
}

@mixin heading-spjold {
  @include heading($heading-spjold-size-min, $heading-spjold-size);
  line-height: div(24, 18);
  font-weight: 500;

  color: var(--color-primary-ui, $color-primary);
}

@mixin numbers {
  @include heading($numbers-size-min, $numbers-size);
  font-weight: 500;
  line-height: div(48, 28);

  color: var(--color-primary-ui, $color-primary);
}

@mixin paragraph-large {
  @include heading($paragraph-large-size-min, $paragraph-large-size);
  line-height: div(32, 20);

  color: $color-secondary-400;
}

@mixin paragraph-medium {
  @include heading($font-size-min, $font-size);
  line-height: $line-height;
}

@mixin paragraph-small {
  @include heading($paragraph-small-size-min, $paragraph-small-size);
  line-height: div(20, 14);
}

@mixin form-label-small {
  padding-bottom: $ui-spacing;

  width: 100%;

  font-size: $font-size-xs;
  line-height: div(20, 14);

  color: var(--color-input-label, $color-font);

  &:empty {
    display: none;
  }
}

// convert pixel value to rem based on 16px default
@function px-to-rem($value) {
  @return math.div(strip-unit($value), 16) * 1rem;
}
