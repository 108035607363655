// stylelint-disable no-descending-specificity

@function shadow($dir: 90deg) {
  $shade: 0.1;

  @return linear-gradient(
    $dir,
    rgba(black, $shade) 0,
    rgba(black, $shade * 0.4) 50%,
    transparent 100%
  );
}

.TableWrapper {
  position: relative;

  &--at::before,
  &--at::after {
    content: '';

    position: absolute 0 _;

    width: 20px;

    pointer-events: none;

    transition: opacity 250ms ease-in-out;
  }

  &--at::before {
    left: 0;

    background-image: shadow(90deg);
  }

  &--at::after {
    right: 0;

    background-image: shadow(-90deg);
  }

  &--at--start::before {
    opacity: 0;
  }

  &--at--end::after {
    opacity: 0;
  }

  &__scroller {
    overflow-x: auto;
  }
}

.Table {
  width: 100%;

  td,
  th {
    @include paragraph-small;
    padding: 8px 5px;

    border-bottom: 1px solid $color-secondary-100;

    text-align: left;
    white-space: pre;

    color: $color-secondary-400;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    font-weight: 500;
  }

  & &__num {
    text-align: right;
  }

  [class^='Table__status--'] {
    display: inline-block;

    margin-top: -5px;

    font-size: 30px;
    line-height: 8px;
    vertical-align: middle;
  }
  .Table__status--positive {
    color: $color-green;
  }
  .Table__status--negative {
    color: $color-red;
  }
}
