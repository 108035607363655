/* stylelint-disable */
html.modal-open {
  margin-right: var(--browser-scrollbar-width);

  overflow: hidden;
}

html.modal-open .Modal {
  overflow-y: auto;
}

.Modalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  min-height: 100vh;
  overflow: hidden;

  transition: all 150ms ease-in-out;
  transition-property: opacity, visibility;

  &::after {
    @include fit;
    content: '';
    background-color: var(--color-primary-ui, $color-primary);
    opacity: 0.8;
  }
}
[hidden].Modalwrapper {
  display: block; // override default `[hidden][hidden]` styling

  visibility: hidden;
  opacity: 0;

  > .Modal {
    opacity: 0;

    transform: translateY(-25%);
  }
}

.Modal {
  position: absolute;
  top: 30px;
  right: 15px;
  left: 15px;
  z-index: 10002;

  margin: 0 auto 20px;

  width: 95%;
  max-width: 628px;
  max-height: calc(100vh - 60px);

  background-color: #fff;
  padding: $gutter;

  border-radius: 12px;

  background: #fff;
  box-shadow: 0px 18px 60px rgba(0, 0, 0, 0.05), 0px 3.6px 9.75px rgba(0, 0, 0, 0.025);

  @media (min-width: $min-desktop) {
    top: 100px;

    max-height: calc(100vh - 200px);
  }

  &__closebutton {
    display: block;

    position: absolute;
    z-index: 5;
    top: 15px;
    right: 15px;

    padding: 5px;

    font-size: 14px;
  }
}
