@use 'sass:math';

// return unitless value
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// scale block according to specified font size.
// $size is pixel value of desired width, optional font-size
// would rather we "show working" rather than use mixins, but
// using mixin at least prevents having to @use math module
@function scale-to-em($size, $scale-font-size: $font-size) {
  @return math.div(strip-unit($size), strip-unit($scale-font-size)) * 1em;
}

@function div($div1, $div2) {
  @return math.div($div1, $div2);
}

@function make-percentage($a, $b) {
  @return math.div(strip-unit($a), strip-unit($b)) * 100%;
}

// GENERIC css fit to relative parent
@mixin fit($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin image-fit($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 50% 50%;
}

// util for hiding
@mixin visually-hidden {
  position: absolute;

  height: 1px;
  width: 1px;
  overflow: hidden;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);

  white-space: nowrap;
}

// <Container>: center & left/right pad elements
@mixin container($fluid: false) {
  flex-grow: 1;

  margin: 0 auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  width: 100%;
}

// <Section>: full-width; top & bottom padding for a section component. usually direct parent of <Container />
@mixin section(
  $padding-top: 0,
  $padding-bottom: 0,
  $padding-top-mobile: 0,
  $padding-bottom-mobile: 0
) {
  // Set up unitless values
  $top-mobile: strip-unit($padding-top-mobile);
  $bottom-mobile: strip-unit($padding-bottom-mobile);
  $top: strip-unit($padding-top);
  $bottom: strip-unit($padding-bottom);

  flex: none;

  padding-top: $top-mobile * 1px;
  padding-bottom: $bottom-mobile * 1px;

  width: 100%;

  overflow: hidden;

  // todo: clamp() should fallback to max(min, max) & then percentage between tablet & limit;

  @supports (padding: clamp(1px, 1%, 2px)) {
    padding-top: clamp(
      #{$top-mobile * 1px},
      #{make-percentage(($top * 1px), $page-width)},
      #{$top * 1px}
    );
    padding-bottom: clamp(
      #{$bottom-mobile * 1px},
      #{make-percentage($bottom * 1px, $page-width)},
      #{$bottom * 1px}
    );
  }

  @supports not (padding: clamp(1px, 1%, 2px)) {
    @media (min-width: $min-desktop) {
      padding-top: $top * 1px;
      padding-bottom: $bottom * 1px;
    }
  }

  &:empty {
    display: none;
  }
}

// BUTTON styles. used in input[type="button"] and the <Button /> component
@mixin button {
  @if variable-exists(font-size-button-min) and variable-exists(font-size-button) {
    @include responsive-font($font-size-button-min, $font-size-button);
  }

  appearance: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: $gutter;

  padding: 5px var(--button-inline-padding, 20px);

  width: 100%;
  max-width: $button-width;
  max-width: var(--button-max-width, #{$button-width});

  min-height: $button-height;

  border-radius: var(--button-border-radius, 4px);

  font-weight: 500;
  line-height: 1.1;
  text-decoration: none;

  background-color: var(--color-button-primary, var(--color-primary));
  color: var(--color-button-primary-text, var(--color-primary-text));

  transition: all 0.15s ease-in-out;

  &.large {
    min-height: px-to-rem(64px);
    max-width: var(--button-max-width, 100%);
  }

  &.bigger {
    min-height: px-to-rem(56px);
    max-width: var(--button-max-width, 100%);
  }

  &.small {
    min-height: px-to-rem(40px);
    max-width: var(--button-max-width, #{px-to-rem(176px)});
  }

  &:hover,
  &:focus-visible {
    filter: brightness(90%);

    color: var(--color-button-primary-text, var(--color-primary-text));
    background-color: var(--color-button-primary, var(--color-primary));
  }

  &.disabled,
  &:disabled {
    cursor: default;
    pointer-events: none;

    opacity: var(--disabled-button-opacity, 1);

    background-color: var(--color-button-disabled, var(--color-primary-400));

    color: var(--color-button-disabled-text, var(--color-primary-text));
    box-shadow: none;
  }
}

@mixin secondary-button {
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-top: $gutter;

  padding: 5px 20px;

  width: 100%;
  max-width: $button-width;
  min-height: $button-height;

  border: 2px solid var(--color-primary, $color-primary);
  border-radius: 4px;

  font-weight: 500;
  line-height: 1.1;
  text-decoration: none;

  background-color: #fff;
  color: var(--color-primary-ui);

  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus-visible {
    text-decoration: none;

    color: var(--color-button-primary-text, var(--color-primary-text));
    background-color: var(--color-primary, $color-primary);

    filter: brightness(100%);
  }
}

// generic text-type input styles
@mixin input-defaults {
  display: inline-block;

  padding-left: 0.5ch;
  padding-right: 0.5ch;
  padding-top: 0;
  padding-bottom: 0;

  border: 1px solid var(--color-border);

  @if variable-exists(input-height) {
    display: inline-flex;
    align-items: center;

    height: $input-height;
  }

  &:disabled {
    color: var(--color-disabled, rgba($color-font, 0.6));
  }
}
// save having to @use scss math module per css module
@mixin aspect-ratio($width, $height) {
  display: block;

  padding-bottom: math.div($height, $width) * 100%;
}

@mixin loading-indicator(
  $size: 120px,
  $border-size: 10px,
  $border-color: $color-loading-border
) {
  display: block;

  position: relative;

  margin: auto;

  width: $size;
  height: $size;

  overflow: hidden;

  border: $border-size solid rgba($border-color, 0.2);
  border-left: $border-size solid rgb($border-color);
  border-radius: 50%;

  text-indent: calc(100% + 1em);
  white-space: nowrap;

  transform: translateZ(0);

  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin select-chevron($width: 12px) {
  margin-left: 10px;

  width: $width;

  color: $color-secondary-200;

  transition: transform 300ms ease-in;
}

// Spacer for input alignment
@mixin spacer {
  display: var(--spacer-display, none);

  width: 100%;
  height: var(--spacer-height, 50px);

  @media (min-width: $min-tablet) {
    display: block;
  }
}

@mixin date-picker {
  z-index: 1;

  padding: 10px;

  border-radius: 4px;

  background-color: #fff;
  box-shadow: 0px 18px 60px rgba(0, 0, 0, 0.05), 0px 3.6px 9.75px rgba(0, 0, 0, 0.025);

  &__input {
    --rdp-accent-color: var(--color-primary);
  }
}
